
import { defineComponent, reactive, toRefs, ref, onMounted,markRaw } from 'vue'
import { ElMessage } from 'element-plus'
import { Md5 } from 'ts-md5/dist/md5'
import * as echarts from 'echarts';
import formatDate from './../util/formatDate';
import {
  isEditAuthByCode,
  getCurrentMonthUser,
  getCurrentMonthDevice,
  getIMEIUsageInformation,
  getOnlineAndOfflineInformation,
  getTotalNumberOfUsersAndDevices
} from '@/api/index'
interface deviceDataF {
    currentMonthUserChart:any
    currentMonthDeviceChart:any
    imeiUsageInformationChart:any
    onlineAndOfflineInformationChart:any
    totalNumberOfUsersAndDevicesChart:any
    devType:any
    timer:any
}

export default defineComponent({
  name: 'HomeReport',
  components: {},
  setup() {
    const editRef = ref()
    const deviceData: deviceDataF = reactive({
        devType:{"1":"车联网","2":"手表","3":"宠物"},
        timer:null,
        currentMonthUserChart:{
            options: {
                title: {
                    text: '一个月的每天的用户增长数量'
                },
                 tooltip : { 
                    trigger: 'axis',
                    show:true,
                    axisPointer:{
                        type: 'cross'
                    }
                },
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    top:"40px",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                   // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: [{
                    type: 'value'
                }],
                series: [
                    // {
                    // data: [150, 230, 224, 218, 135, 147, 260],
                    // type: 'line',
                    // smooth: true
                    // }
                    {
                        type: 'line',
                        name: '数量',
                        smooth: true
                    }
                ]
            }
        },
        currentMonthDeviceChart:{
            options: {
                legend: {
                    data: ['车载', '宠物', '手表']
                },
                title: {
                    text: '一个月各设备类型接入变化情况'
                },
                 tooltip : { 
                    trigger: 'axis',
                    show:true,
                    axisPointer:{
                        type: 'cross'
                    }
                },
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    top:"40px",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                     boundaryGap: false
                },
                yAxis: [{
                    type: 'value'
                }],
                series: [
                    {
                        name:"车载",
                        type: 'line',
                        smooth: true
                    },
                    {
                        name:"宠物",
                        type: 'line',
                        smooth: true
                    },
                    {
                        name:"手表",
                        type: 'line',
                        smooth: true
                    }
                ]
            }
        },
        imeiUsageInformationChart:{
            options:{

                title: {
                    text: 'imei使用信息'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '1%',
                    top:"40px",
                    containLabel: true
                },
                xAxis: [
                    {
                    type: 'category', 
                    data: [],
                    axisTick: {
                        alignWithLabel: true
                    }
                    }
                ],
                yAxis: [
                    {
                    type: 'value'
                    }
                ],
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        barWidth: '60%'
                    }
                ]
            }
        },
        onlineAndOfflineInformationChart:{
            options:{
                title: {
                    text: '各设备类型在/离线情况'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    top:"40px",
                    containLabel: true
                },
                xAxis: [
                    {
                    type: 'category',
                    data: []//['车联网', '手表', '宠物']
                    }
                ],
                yAxis: [
                    {
                    type: 'value'
                    }
                ],
                series: [
                     {
                        name: '总',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        }
                    },
                    {
                        name: '在线',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        }
                    },
                    {
                        name: '离线',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        }
                    }
                ]
            }
        },
        totalNumberOfUsersAndDevicesChart:{
            options:{
                title: {
                    text: '用户/车载/宠物/手表数量',
                    // subtext: 'Fake Data\r\r\r',
                    left: 'left'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'right'
                },
                series: [
                    {
                        // name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: [
                            // { value: 108, name: '用户' },
                            // { value: 1048, name: '车联网' },
                            // { value: 735, name: '手表' },
                            // { value: 580, name: '宠物' }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }

    })

    const data = toRefs(deviceData)
    onMounted(() => {
      getCurrentMonthUserList()
      currentMonthDeviceList()
      imeiUsageInformationList()
      onlineAndOfflineInformationList()
      totalNumberOfUsersAndDevicesList()

      if(deviceData.timer!=null){
          clearInterval(deviceData.timer)
          deviceData.timer = null;
      }
      deviceData.timer = setInterval(() => {
        if(document.getElementById('currentMonthUserChartId')==null){
            console.log("已不在当前页");
            return;
        }
      getCurrentMonthUserList()
      currentMonthDeviceList()
      imeiUsageInformationList()
      onlineAndOfflineInformationList()
      totalNumberOfUsersAndDevicesList()
      }, 60000 * 5 )
      
    })

    const getCurrentMonthUserList = async () => {
        if((document.getElementById('currentMonthUserChartId') as any).getAttribute('_echarts_instance_')){
          (document.getElementById('currentMonthUserChartId') as any).removeAttribute('_echarts_instance_');
      }
      deviceData.currentMonthUserChart.chart = markRaw(echarts.init((document.getElementById('currentMonthUserChartId') as any)));
      deviceData.currentMonthUserChart.chart.showLoading({ text: '加载中...', effect: 'whirling' });
      await getCurrentMonthUser({}).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){

                let json:any = {};
                for(let obj of res.data.list){
                    json[obj.time] = obj.userCount;
                }

                // console.log("startDate: "+formatDate.formatDateTime(formatDate.strToDate(res.data.startDate)))
                // console.log("addDay: "+formatDate.formatDateTime(formatDate.addDay(formatDate.strToDate(res.data.startDate),32)))
                // console.log("endDate: "+formatDate.formatDateTime(formatDate.strToDate(res.data.endDate)))
                // console.log("endDate: "+formatDate.handleTime(formatDate.strToDate(res.data.endDate),"MM-dd"))

                // let date = formatDate.addDay(formatDate.strToDate(res.data.startDate),32);
                // console.log("compareDate: "+formatDate.compareDate( date ,formatDate.strToDate(res.data.endDate)));
                // console.log("dateDifferenceDay: "+formatDate.dateDifferenceDay( formatDate.strToDate(res.data.startDate) ,formatDate.strToDate(res.data.endDate)));
                // console.log("dateDifferenceDay: "+formatDate.dateDifferenceDay( formatDate.strToDate(res.data.endDate) ,formatDate.strToDate(res.data.startDate)));

                // console.log("startDate: "+formatDate.formatDateTime(formatDate.strToDate(res.data.startDate)))
                // console.log("endDate: "+formatDate.formatDateTime(formatDate.strToDate(res.data.endDate)))
                let day = formatDate.dateDifferenceDay( formatDate.strToDate(res.data.startDate) ,formatDate.strToDate(res.data.endDate));

                 let dataArray = [];
                let keyArray = [];

                for(let i = 0 ; i <= day ; i++ ){
                    let newDate = formatDate.addDay(formatDate.strToDate(res.data.startDate),i);
                    let dateKey = formatDate.handleTime(newDate,"MM-dd");
                    if(json[dateKey]==null){
                        json[dateKey] = 0;
                    }
                    dataArray.push(json[dateKey]);
                    keyArray.push(dateKey);
                    // console.log("formatDateTime: "+formatDate.formatDateTime(newDate)+" dateKey: "+dateKey+"value: "+json[dateKey]);
                }

                deviceData.currentMonthUserChart.options.series[0].data = dataArray;
                deviceData.currentMonthUserChart.options.xAxis.data = keyArray;
                deviceData.currentMonthUserChart.chart.setOption(deviceData.currentMonthUserChart.options);
              }else{
                  ElMessage.success('加载成功，数据为空！');
              }
          }
          else if(res.errMsg!=null){
                ElMessage.error(res.errMsg);
            }else{
                ElMessage.error("加载失败");
            }
          setTimeout(function(){
              deviceData.currentMonthUserChart.chart.hideLoading();
          },10);
          
        }
      )
    }
    
    const currentMonthDeviceList = async () => {
        if((document.getElementById('currentMonthDeviceChartId') as any).getAttribute('_echarts_instance_')){
          (document.getElementById('currentMonthDeviceChartId') as any).removeAttribute('_echarts_instance_');
      }
      deviceData.currentMonthDeviceChart.chart = markRaw(echarts.init((document.getElementById('currentMonthDeviceChartId') as any)));
      deviceData.currentMonthDeviceChart.chart.showLoading({ text: '加载中...', effect: 'whirling' });
      await getCurrentMonthDevice({}).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.map!=null){

                let car = res.data.map.car;
                let watch = res.data.map.watch;
                let pet = res.data.map.pet;
                let carJson:any = {};
                for(let obj of car){
                    carJson[obj.time] = obj.count;
                }
                let watchJson:any = {};
                for(let obj of watch){
                    watchJson[obj.time] = obj.count;
                }
                let petJson:any = {};
                for(let obj of pet){
                    petJson[obj.time] = obj.count;
                }
                let day = formatDate.dateDifferenceDay( formatDate.strToDate(res.data.startDate) ,formatDate.strToDate(res.data.endDate));

                 let carDataArray = [];
                 let watchDataArray = [];
                 let petDataArray = [];
                let keyArray = [];

                for(let i = 0 ; i <= day ; i++ ){
                    let newDate = formatDate.addDay(formatDate.strToDate(res.data.startDate),i);
                    let dateKey = formatDate.handleTime(newDate,"MM-dd");
                    if(carJson[dateKey]==null){
                        carJson[dateKey] = 0;
                    }
                    carDataArray.push(carJson[dateKey]);

                    if(watchJson[dateKey]==null){
                        watchJson[dateKey] = 0;
                    }
                    watchDataArray.push(watchJson[dateKey]);

                    if(petJson[dateKey]==null){
                        petJson[dateKey] = 0;
                    }
                    petDataArray.push(petJson[dateKey]);

                    keyArray.push(dateKey);
                }

                deviceData.currentMonthDeviceChart.options.series[0].data = carDataArray;
                
                deviceData.currentMonthDeviceChart.options.series[1].data = petDataArray;
                deviceData.currentMonthDeviceChart.options.series[2].data = watchDataArray;
                
                deviceData.currentMonthDeviceChart.options.xAxis.data = keyArray;
                deviceData.currentMonthDeviceChart.chart.setOption(deviceData.currentMonthDeviceChart.options);
              }else{
                  ElMessage.success('加载成功，数据为空！');
              }
          }
          else if(res.errMsg!=null){
                ElMessage.error(res.errMsg);
            }else{
                ElMessage.error("加载失败");
            }
          setTimeout(function(){
              deviceData.currentMonthDeviceChart.chart.hideLoading();
          },10);
          
        }
      )
    }

    const imeiUsageInformationList = async () => {
        if((document.getElementById('imeiUsageInformationChartId') as any).getAttribute('_echarts_instance_')){
          (document.getElementById('imeiUsageInformationChartId') as any).removeAttribute('_echarts_instance_');
      }
      deviceData.imeiUsageInformationChart.chart = markRaw(echarts.init((document.getElementById('imeiUsageInformationChartId') as any)));
      deviceData.imeiUsageInformationChart.chart.showLoading({ text: '加载中...', effect: 'whirling' });
      await getIMEIUsageInformation({}).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  let dataArray = [];
                  deviceData.imeiUsageInformationChart.options.xAxis[0].data = [];
                  for(let obj of res.data.list){
                      deviceData.imeiUsageInformationChart.options.xAxis[0].data.push(getDevType(obj.type));
                      dataArray.push(obj.count);
                  }

                deviceData.imeiUsageInformationChart.options.series[0].data = dataArray;
                
                deviceData.imeiUsageInformationChart.chart.setOption(deviceData.imeiUsageInformationChart.options);
              }else{
                  ElMessage.success('加载成功，数据为空！');
              }
          }
          else if(res.errMsg!=null){
                ElMessage.error(res.errMsg);
            }else{
                ElMessage.error("加载失败");
            }
          setTimeout(function(){
              deviceData.imeiUsageInformationChart.chart.hideLoading();
          },10);
          
        }
      )
    }

      const getDevType = (type: any) => {
          if (!type) {
              return '其它';
        }
          let name = deviceData.devType[type];
          if (name) {
              return name;
          } else {
              return type;
        }
    }
    
    const onlineAndOfflineInformationList = async () => {
        if((document.getElementById('onlineAndOfflineInformationChartId') as any).getAttribute('_echarts_instance_')){
          (document.getElementById('onlineAndOfflineInformationChartId') as any).removeAttribute('_echarts_instance_');
      }
      deviceData.onlineAndOfflineInformationChart.chart = markRaw(echarts.init((document.getElementById('onlineAndOfflineInformationChartId') as any)));
      deviceData.onlineAndOfflineInformationChart.chart.showLoading({ text: '加载中...', effect: 'whirling' });
      await getOnlineAndOfflineInformation({}).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  let totalDataArray = [];
                  let onlineDataArray = [];
                  let offlineDataArray = [];
                  deviceData.onlineAndOfflineInformationChart.options.xAxis[0].data = [];
                  for(let obj of res.data.list){
                      deviceData.onlineAndOfflineInformationChart.options.xAxis[0].data.push(getDevType(obj.type));
                      totalDataArray.push(obj.totalNum);
                      onlineDataArray.push(obj.onlineNum);
                      offlineDataArray.push(obj.offlineNum);
                  }

                deviceData.onlineAndOfflineInformationChart.options.series[0].data = totalDataArray;
                
                
                deviceData.onlineAndOfflineInformationChart.options.series[1].data = onlineDataArray;
                
                deviceData.onlineAndOfflineInformationChart.options.series[2].data = offlineDataArray;

                deviceData.onlineAndOfflineInformationChart.chart.setOption(deviceData.onlineAndOfflineInformationChart.options);
              }else{
                  ElMessage.success('加载成功，数据为空！');
              }
          }
          else if(res.errMsg!=null){
                ElMessage.error(res.errMsg);
            }else{
                ElMessage.error("加载失败");
            }
          setTimeout(function(){
              deviceData.onlineAndOfflineInformationChart.chart.hideLoading();
          },10);
          
        }
      )
    }
    
    const totalNumberOfUsersAndDevicesList = async () => {
        if((document.getElementById('totalNumberOfUsersAndDevicesChartId') as any).getAttribute('_echarts_instance_')){
          (document.getElementById('totalNumberOfUsersAndDevicesChartId') as any).removeAttribute('_echarts_instance_');
      }
      deviceData.totalNumberOfUsersAndDevicesChart.chart = markRaw(echarts.init((document.getElementById('totalNumberOfUsersAndDevicesChartId') as any)));
      deviceData.totalNumberOfUsersAndDevicesChart.chart.showLoading({ text: '加载中...', effect: 'whirling' });
      await getTotalNumberOfUsersAndDevices({}).then(
        (res: any) => {
          if (res.code == 0) {
              deviceData.totalNumberOfUsersAndDevicesChart.options.series[0].data = [];
              if(res.data!=null && res.data.list!=null){
                  
                  deviceData.totalNumberOfUsersAndDevicesChart.options.series[0].data.push({value:res.data.list[0].userCount,name:"用户"});
                  deviceData.totalNumberOfUsersAndDevicesChart.options.series[0].data.push({value:res.data.list[0].carCount,name:getDevType(1)});
                  deviceData.totalNumberOfUsersAndDevicesChart.options.series[0].data.push({value:res.data.list[0].watchCount,name:getDevType(2)});
                  deviceData.totalNumberOfUsersAndDevicesChart.options.series[0].data.push({value:res.data.list[0].petCount,name:getDevType(3)});
                  deviceData.totalNumberOfUsersAndDevicesChart.chart.setOption(deviceData.totalNumberOfUsersAndDevicesChart.options);
              }else{
                  ElMessage.success('加载成功，数据为空！');
              }
          }
          else if(res.errMsg!=null){
                ElMessage.error(res.errMsg);
            }else{
                ElMessage.error("加载失败");
            }
          setTimeout(function(){
              deviceData.totalNumberOfUsersAndDevicesChart.chart.hideLoading();
          },10);
          
        }
      )
    }
    
    
    return {
      ...data,
      editRef,

      isEditAuthByCode
    }
  },
})
